import React from "react";
import Doughnut from "../donut";

export default function DonutGraphicCategories({ score, resultats }) {
  return (
    <div className=" tw-flex tw-flex-1 tw-flex-col tw-gap-8">
      <div className="tw-flex tw-flex-col tw-gap-4 tw-h-full tw-justify-between">
        <div className="tw-wrapper-donut tw-relative tw-flex tw-justify-center">
          <Doughnut score={score} />
          <div className="tw-flex tw-justify-center tw-absolute tw-bottom-20 tw-left-0 tw-right-0">
            <span className="tw-text-xl tw-font-bold tw-uppercase">{resultats}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
