import React, { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { restconnect } from "../modules/restconnect";
import functions from "../modules/functions";
import Progressbar from "../library/progressbar";

function Survey() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [userid, setUserid] = useState('');
    const f = functions;

    const [questions, setQuestions] = useState([]);
    const [returndata, setReturndata] = useState([]);
    const questionStack = useRef([]);

    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentAnswers, setCurrentAnswers] = useState([]);
    const [currentAnswerWeights, setCurrentAnswerWeights] = useState([]);
    const [currentCategory, setCurrentCategory] = useState("");

    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [clickedIndex, setClickedIndex] = useState(null); // Add state for clicked index
    const [complete, setComplete] = useState(false);
    const [showNext, setShowNext] = useState(false);

    const nextButtonRef = useRef(null);

    useEffect(() => {
        let param = { 'task': 'get_questions', 'surveyid': 1 };
        restconnect(param, 'POST').then(r => {
            setQuestions(r);
        });

        const handleKeyDown = (event) => {
            if (event.key === 'Enter' && showNext && nextButtonRef.current) {
                nextButtonRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [id, showNext]);

    const beginAction = () => {
        setUserid(f.getUserId());
        setCurrentQuestion(0);
        setCurrentAnswers(questions[0]["answer"].split(","));
        setCurrentAnswerWeights(questions[0]["answerweight"].split(","));
        setCurrentCategory(questions[0]["category"]);
        window.scroll({ top: 800, behavior: 'smooth' });
    };

    const onHandleChange = (index) => {
        console.log("index", index);
        setClickedIndex(index); // Set the clicked index
        console.log("ok");

        // Check if the current question allows multiple answers
        const isMultipleQuestion = questions[currentQuestion]["multiplequestions"] === 1;

        if (!isMultipleQuestion) {
            // For single-choice questions, uncheck all other boxes
            uncheckBoxes(index);
        }

        const selectedOption = document.getElementById("answer_" + index).checked;

        // Update selectedAnswers state based on the option being checked/unchecked
        setSelectedAnswers((prevSelectedAnswers) => {
            if (selectedOption) {
                return [...prevSelectedAnswers, index];
            } else {
                return prevSelectedAnswers.filter((i) => i !== index);
            }
        });

        // Check the current selected answers after state update
        const updatedSelectedAnswers = selectedOption
            ? [...selectedAnswers, index]
            : selectedAnswers.filter((i) => i !== index);

        // Logic to determine the checked state for the next button or completion
        let checked;
        if (isMultipleQuestion) {
            // If it's a multiple-choice question, checked is true if any option is selected
            checked = updatedSelectedAnswers.length > 0;
        } else {
            // Otherwise, follow the single-choice logic
            checked = selectedOption;
        }

        console.log(checked, currentQuestion, questions.length);

        // Show the next button or mark as complete based on the current question
        if (currentQuestion < questions.length) {
            if (checked) {
                setShowNext(true);
                setComplete(false);
            } else {
                setShowNext(false);
                setComplete(false);
            }
        } else {
                setShowNext(false);
                setComplete(true);
        }
    };



    const previousAction = () => {
        if (currentQuestion > 0) {
            let tempCurrentQuestion = questionStack.current.pop();
            setCurrentQuestion(tempCurrentQuestion);
            setCurrentAnswers(questions[tempCurrentQuestion]["answer"].split(","));
            setCurrentAnswerWeights(questions[tempCurrentQuestion]["answerweight"].split(","));
            setCurrentCategory(questions[tempCurrentQuestion]["category"]);
            setShowNext(false);
        }
    };

    const nextAction = () => {
        if (selectedAnswers.length > 0 && currentQuestion + 1 < questions.length) {
            const selectedAnswerTexts = selectedAnswers.map((i) => currentAnswers[i].replace("'", "#"));
            const selectedWeights = selectedAnswers.map((i) => parseFloat(currentAnswerWeights[i]));
            const averageWeight = selectedWeights.reduce((a, b) => a + b, 0) / selectedWeights.length;

            const newReturndata = [...returndata];
            newReturndata[currentQuestion] = {
                id: questions[currentQuestion]["questionid"],
                a: selectedAnswerTexts,
                w: averageWeight.toFixed(2),
                c: currentCategory,
                p: selectedAnswers
            };
            setReturndata(newReturndata);

            let tempCurrentQuestion = currentQuestion + 1;
            const trimmedAnswer = selectedAnswerTexts[0].trim();
            if ((trimmedAnswer === "Non" || trimmedAnswer === "Pas de sauvegarde") && questions[currentQuestion]['thread'] > 0) {
                const nextQuestionId = questions[currentQuestion]['thread'];
                const nextQuestionIndex = questions.findIndex((q) => q['questionid'] === nextQuestionId);
                if (nextQuestionIndex !== -1) {
                    tempCurrentQuestion = nextQuestionIndex;
                }
            }

            setCurrentQuestion(tempCurrentQuestion);
            setCurrentAnswers(questions[tempCurrentQuestion]["answer"].split(","));
            setCurrentAnswerWeights(questions[tempCurrentQuestion]["answerweight"].split(","));
            setCurrentCategory(questions[tempCurrentQuestion]["category"]);
            setSelectedAnswers([]);
            setShowNext(false);
            questionStack.current.push(currentQuestion);
            resetCheckboxes();
        } else {
            setShowNext(false);
            setComplete(true);
        }
    };

    const submitAction = () => {
        let totalSum = 0;
        let categorySum = { 1: 0, 2: 0, 3: 0, 4: 0 };
        let categoryTotal = { 1: 0, 2: 0, 3: 0, 4: 0 };

        returndata.forEach((data) => {
            if (data) {
                totalSum += parseFloat(data.w);
                categorySum[data.c] += parseFloat(data.w);
                categoryTotal[data.c] += 1;
            }
        });

        const totalCategoryTotal = Object.values(categoryTotal).reduce((acc, val) => acc + val, 0);

        const totalscore = Math.round(totalSum * 100 / totalCategoryTotal);
        const category1 = Math.round(categorySum[1] * 100 / categoryTotal[1]);
        const category2 = Math.round(categorySum[2] * 100 / categoryTotal[2]);
        const category3 = Math.round(categorySum[3] * 100 / categoryTotal[3]);
        const category4 = Math.round(categorySum[4] * 100 / categoryTotal[4]);

        const responsobject = {
            'surveyid': 1,
            'userid': userid,
            'response': returndata.map(data => data ? {
                ...data,
                a: data.a.map(answer => encodeURIComponent(answer))
            } : data),
            'totalscore': totalscore,
            'category1': category1,
            'category2': category2,
            'category3': category3,
            'category4': category4,
        };

        const params = { 'task': 'save_questions', 'response': responsobject };
        restconnect(params, 'POST').then((r) => {});
        navigate('/summary');
    };

    const resetCheckboxes = () => {
        for (let i = 0; i < currentAnswers.length; i++) {
            const itemcheck = document.getElementById('answer_' + i);
            if (itemcheck) {
                itemcheck.checked = false;
            }
        }
    };

    const uncheckBoxes = (index) => {
        for (let i = 0; i < currentAnswers.length; i++) {
            const itemcheck = document.getElementById('answer_' + i);
            if (index === i) {
                if (!itemcheck.checked) {
                    itemcheck.checked = false;
                }
            } else {
                itemcheck.checked = false;
            }
        }
    };

    const progress = Math.ceil(((currentQuestion + 1) / questions.length) * 100);

    return (
        <>
            <section className="work-progress-area bgs-cover py-1 rpy-5">
                <div className="container">
                    <div className="work-progress-inner bg-blue text-white col-lg-12 col-md-8 col-sm-10 mt-30"
                         style={{position: 'relative', borderRadius: '5px'}}>
                        <div className="section-title-with-btn mb-10 col-12"
                             style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <div className="section-title col-6" style={{display: 'flex', alignItems: 'center'}}>
                                <h2 style={{marginTop: '20px'}}>Audit NIS</h2>
                            </div>
                            <div className="button-group" style={{display: 'flex', gap: '10px'}}>
                                <button
                                    className="btn btn-outline-light"
                                    style={{fontSize: '14px'}}
                                    onClick={() => {
                                        window.location.href = '/summary';
                                    }}
                                >
                                    Retour au tableau de bord
                                </button>
                                {currentQuestion === null &&
                                    <button
                                        type="submit"
                                        className="btn btn-lg btn-outline-light"
                                        style={{fontSize: '14px'}}
                                        onClick={beginAction}
                                    >
                                        Démarrer l'audit
                                    </button>
                                }
                            </div>
                        </div>
                        <div style={{marginLeft: '30px'}}>
                            <h7>Progrès</h7>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1px',
                            width: '100%',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                            boxSizing: 'border-box'
                        }}>
                            <div style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                marginBottom: '10px',
                                height: '48px'
                            }}>
                                <Progressbar
                                    bgcolor="#104cba"
                                    progress={progress.toString()}
                                    height={27}
                                    width="100%"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="featured-section bgs-cover rpt-150 pb-120 rpb-100">
                <div className="container">
                    <div className="row">
                        {(currentQuestion !== null) && <>
                            <div className="col-lg-12 col-md-8 col-sm-10 mt-30">
                                <div className="section-title feature-item mb-35">
                                    <div className="icon col-12">
                                        <i className={"flaticon-vector"}>&nbsp;</i>
                                        {!complete && <>
                                            <div style={{height: '60px'}}>
                                                <span>{"Question " + (currentQuestion + 1) + " sur " + questions.length}</span><br/>
                                            </div>
                                            <div style={{height: '80px'}}>
                                                <h3>{questions[currentQuestion]['questionid']} {questions[currentQuestion]['questiontext']}</h3>
                                            </div>

                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between',
                                                height: '240px'
                                            }}>
                                                <div style={{flex: 1}}>
                                                    {currentAnswers.map((item, index) => (
                                                        <div className="col-lg-12 ml-10" key={"div_" + index}>
                                                            <input className="form-check-input"
                                                                   type={questions[currentQuestion]?.multiplequestions === 1 ? "checkbox" : "radio"}
                                                                   value=""
                                                                   id={"answer_" + index}
                                                                   key={"answerkey_" + index}
                                                                   style={{
                                                                       transform: 'scale(1.5)',
                                                                       marginRight: '12px'
                                                                   }}
                                                                   onChange={() => onHandleChange(index)}
                                                            />
                                                            <label className="form-check-label"
                                                                   key={"answerlabel_" + index}
                                                                   htmlFor={"answer_" + index}>{item}</label>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            <div style={{
                                                marginTop: '15px',
                                                padding: '20px',
                                                marginBottom: '15px',
                                                borderTop: '1px solid lightgray'
                                            }}>
                                                {questions[currentQuestion]?.multiplequestions === 1 && (
                                                    <span>
                                                        <input type="checkbox" style={{
                                                            cursor: 'default',
                                                            transform: 'scale(1.5)',
                                                            marginRight: '10px'
                                                        }} disabled/>
                                                        <label
                                                            style={{
                                                                cursor: 'default',
                                                                color: 'gray'
                                                            }}>Choix multiples</label>
                                                    </span>
                                                )}
                                                {questions[currentQuestion]?.multiplequestions !== 1 && (
                                                    <span>
                                                        <input type="radio" style={{
                                                            cursor: 'default',
                                                            transform: 'scale(1.5)',
                                                            marginRight: '10px'
                                                        }} disabled/>
                                                        <label style={{
                                                            cursor: 'default',
                                                            color: 'gray'
                                                        }}>Choix unique</label>
                                                    </span>
                                                )}
                                            </div>

                                            <div className="button-container" style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                marginTop: '15px',
                                                minHeight: '60px'
                                            }}>
                                                {questions[currentQuestion]["questionid"] !== "1.1" &&
                                                    <button type="button" className="theme-btn float-right mr-30"
                                                            onClick={previousAction}>
                                                        Question précédente
                                                    </button>
                                                }

                                                {showNext &&
                                                    <button type="submit" className="theme-btn float-right mr-30"
                                                            onClick={nextAction} ref={nextButtonRef}>
                                                        Question suivante
                                                    </button>
                                                }
                                            </div>
                                            <div className="row" style={{clear: 'both', width: '100%'}}>
                                                <div className="container" style={{marginTop: '15px'}}>
                                                    <div className="service-normal">
                                                        <h6>Description</h6>
                                                        <p>{questions[currentQuestion]["description"]}</p>
                                                    </div>
                                                </div>
                                                <div className="container" style={{marginTop: '15px'}}>
                                                    <div className="service-normal">
                                                        <h6>Explication</h6>
                                                        <p>{questions[currentQuestion]["explication"]}</p>
                                                    </div>
                                                </div>
                                                <div className="container" style={{marginTop: '15px'}}>
                                                    <div className="service-normal">
                                                        <h6>Recommandation</h6>
                                                        <p>{questions[currentQuestion]["recommandation"]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>}
                                        {complete && <>
                                            <span>Merci</span>
                                            <h4>Merci d'avoir répondu à cette enquête</h4>
                                            <button type="submit" className="theme-btn float-right mr-30"
                                                    onClick={submitAction}>
                                                Sauvegarder
                                            </button>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </section>
        </>
    );
}

export default Survey;
