import React, { useState, useEffect } from "react";
import f from "../modules/functions";
import UncompleteBadge from "../library/uncompletebadge";
import { restconnect } from "../modules/restconnect";
import StripeCheckoutButton from "../library/StripeCheckoutButton";
import { Building, History } from "lucide-react";
import DonutGraphic from "../library/dashboard/donutGraphic";
import BarGraphicsCategories from "../library/dashboard/BarGraphicsCategories";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import {
  CallToActionSection,
  ConformitySection,
  RiskSection,
  TimeSection,
} from "../library/dashboard/categoriesComponents";

const Summary = () => {
  const [user, setUser] = useState(f.getKeyInStorage("user"));
  const [completed, setCompleted] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [products, setProducts] = useState([]);
  const [paymentFeedback, setPaymentFeedback] = useState("");
  const [auditNIS, setAuditNIS] = useState("NIS");
  const [auditISO, setAuditISO] = useState(null);
  const [activeAudit, setActiveAudit] = useState(
      auditNIS ? "NIS" : auditISO ? "ISO" : "NIS"
  );
  const [riskConformityNIS, setRiskConformityNIS] = useState({});
  const [activeTab, setActiveTab] = useState("tab0");
  const [auditDone, setAuditDone] = useState(false);

  let hasLength = false;

  const handleToggle = (auditType) => {
    setActiveAudit(auditType);
  };

  useEffect(() => {
    const fetchQuestions = async () => {
      const param = { task: 'get_questions', surveyid: 1 };
      try {
        const response = await restconnect(param, 'POST');
        if (!response.hasOwnProperty('error')) {
          setQuestions(response);
        }
      } catch (error) {
      }
    };

    if (questions.length < 1) {
      fetchQuestions();
    }
  }, [questions]);

  useEffect(() => {

    let user = f.getKeyInStorage("user");
    let products = f.getKeyInStorage("products");
    setCompleted(user["surveyscomplete"]);

    console.log(user);

    {/*
     setTransactions(
        user["purchases"].hasOwnProperty("error") ? [] : user["purchases"]
    );
    */}

    const userTransactions = user["purchases"].hasOwnProperty("error")
        ? []
        : user["purchases"];

    setTransactions(userTransactions.length > 0 ? userTransactions : [{
      "transactionid": 0,
      "transactiondate": "N/A",
      "address": "Default Address",
      "city": "Default City",
      "country": "Default Country",
      "zip": "0000",
      "cardtype": "Default Card",
      "expyear": 0,
      "payedprice": 0,
      "last4": "0000",
      "productprice": 0,
      "productname": "Default Product",
      "productingress": "",
      "productdescription": "Default Description",
      "Associatedsurveys": "0"
    }]);


    setProducts(products);



    let param = { task: "get_awailable_surveys", uid: user.uid };
    restconnect(param, "POST").then((r) => {
      if (!r.hasOwnProperty("error")) {
        let available = r["asurveys"];
        let complete = r["csurveys"];
        if (complete && complete.error === "sql") {
          setAuditDone(false);
        } else {
          setAuditDone(true);
        }
        let renderAvailable = [];
        for (let i = 0; i < complete.length; i++) {
          for (let j = 0; j < available.length; j++) {
            if (complete[i]["surveyid"] !== available[j]["surveyid"]) {
              renderAvailable.push(available[j]);
            }
          }
          hasLength = true;
        }
        setCompleted(complete);
        setSurveys(hasLength === false ? available : renderAvailable);
        f.storeObject("surveys", renderAvailable);
        f.storeObject("completedsurveys", complete);
      }
    });
  }, []);

  useEffect(() => {
    let user = f.getKeyInStorage("user");
    setCompleted(user["surveyscomplete"]);
    let param = { task: "get_awailable_surveys", uid: user.uid };

    setTransactions(
        user["purchases"].hasOwnProperty("error") ? [] : user["purchases"]
    );

    restconnect(param, "POST").then((r) => {
      if (!r.hasOwnProperty("error")) {
        let available = r["asurveys"];
        let complete = r["csurveys"];
        let renderAvailable = [];

        for (let i = 0; i < complete.length; i++) {
          for (let j = 0; j < available.length; j++) {
            if (complete[i]["surveyid"] !== available[j]["surveyid"]) {
              renderAvailable.push(available[j]);
            }
          }
          hasLength = true;
        }

        const calculateRisk = (score) => 100 - score;

        if (Array.isArray(r["csurveys"])) {
          const { auditNIS, auditISO } = r["csurveys"].reduce(
              (acc, survey) => {
                if (survey.surveyid === 1) {
                  acc.auditNIS = survey;
                } else {
                  acc.auditISO = survey;
                }
                return acc;
              },
              { auditNIS: null, auditISO: null }
          );
          setAuditNIS(auditNIS);
          setAuditISO(auditISO);

          if (auditNIS) {
            const risksNIS = {
              total: calculateRisk(auditNIS.score),
              cat1: calculateRisk(auditNIS.cat1_score),
              cat2: calculateRisk(auditNIS.cat2_score),
              cat3: calculateRisk(auditNIS.cat3_score),
              cat4: calculateRisk(auditNIS.cat4_score),
            };
            setRiskConformityNIS(risksNIS);
          }
          if (auditISO) {
            const risksISO = {
              total: calculateRisk(auditISO.score),
              cat1: calculateRisk(auditISO.cat1_score),
              cat2: calculateRisk(auditISO.cat2_score),
              cat3: calculateRisk(auditISO.cat3_score),
              cat4: calculateRisk(auditISO.cat4_score),
            };
            setRiskConformityNIS(risksISO);
          }
        }

        setCompleted(complete);
        setSurveys(hasLength === false ? available : renderAvailable);
        f.storeObject("surveys", renderAvailable);
        f.storeObject("completedsurveys", complete);
      }
    });
  }, []);

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    document.querySelectorAll(".tw-item-tab").forEach((item) => {
      item.classList.remove("tw-bg-light-blue");
      item.classList.remove("tw-shadow-inner");
    });

    document
        .querySelector(`[data-tab="${tabId}"]`)
        .classList.add("tw-bg-light-blue");
    document
        .querySelector(`[data-tab="${tabId}"]`)
        .classList.add("tw-shadow-inner");
  };

  const error1 =
      "There was an error with your payment: The card was declined";
  const error2 =
      "There was an error with your payment: Other undetermined error";

  const onToken = (token) => {
    let params = token;
    params.task = "requestpayment";
    params.userid = user.uid;

    restconnect(params, "POST").then((r) => {
      if (r.hasOwnProperty("error")) {
        setPaymentFeedback(r["error"] === 1 ? error1 : error2);
      } else {
        setPaymentFeedback("Payment was successful");
        let newuserdata = user;
        newuserdata["purchases"] = r["purchases"];
        setTransactions(r["purchases"]);
        setUser(newuserdata);
        f.storeObject("user", newuserdata);
      }
    });
  };

  const getResultTextConformite = (score) => {
    if (score <= 25) {
      return "Très faible";
    } else if (score > 25 && score < 50) {
      return "faible";
    } else if (score >= 50 && score < 75) {
      return "moyen";
    } else {
      return "élevé";
    }
  };

  const handleCertificate = () => {
    return 1;
  };

  const getResultTextRisque = (score) => {
    if (score <= 25) {
      return "Très faible";
    } else if (score > 25 && score < 50) {
      return "faible";
    } else if (score >= 50 && score < 75) {
      return "moyen";
    } else {
      return "élevé";
    }
  };

  const sybeScore = (score) => {
    if (score < 25) {
      return 1;
    } else if (score > 24 && score < 50) {
      return 2;
    } else if (score > 49 && score < 75) {
      return 3;
    } else {
        return 4;
    }
  };


  const handlePdfDownload = () => {
    const doc = new jsPDF();

    const surveyResponses = JSON.parse(auditNIS.surveyrespons);

    const mappedResponses = surveyResponses.map(response => {
      if (response && response.id) {
        const question = questions.find(q => q.questionid === response.id);
        if (question) {

          // Split the question.answer string into an array
          const answerArray = question.answer.split(', ');

          // Extract the answers corresponding to the indices in response.p
          const answer = Array.isArray(response.p)
              ? response.p
                  .filter(index => index >= 0 && index < answerArray.length) // Ensure indices are within bounds
                  .map(index => answerArray[index]) // Map indices to corresponding answers
                  .join(', ') // Join the selected answers with a comma and space
              : null;

          return {
            questionText: question.questiontext,
            questionid: question.questionid,
            answer: answer, // Use the derived answer
            weight: Array.isArray(response.w) ? response.w.join(', ') : response.w
          };
        }
      }
      return null;
    }).filter(Boolean); // Filter out null values

    // Add logo image instead of company name
    const img = new Image();
    img.src = "/assets/images/logos/logo-web-transparent.png"; // Path to your image
    img.onload = function () {
      // Add the image to the PDF (x, y, width, height)
      doc.addImage(img, 'PNG', 7, 10, 50, 20); // Adjust the position and size as needed

      // Add title after the image
      doc.setFontSize(16);
      doc.text("Réponses à l'audit", 15, 40);

      const tableData = mappedResponses.map((response, index) => [
        response.questionid,
        response.questionText,
        response.answer
      ]);

      // Generate the table
      autoTable(doc, {
        startY: 50,
        head: [['#', 'Questions', 'Réponses']],
        body: tableData,
        styles: { fontSize: 12, cellPadding: 3 },
        headStyles: { fillColor: [0, 102, 204] }, // Blue color for header
        theme: 'grid',
        margin: { top: 50 },
        columnStyles: {
          0: { cellWidth: 20 },
          1: { cellWidth: 60 },
          2: { cellWidth: 110 }
        }
      });

      // Save the PDF
      doc.save('audit.pdf');
    };
  };


  return (
      <>
        <section className="tw-pt-10 tw-dash tw-bg-light-blue">
          <div className="tw-container tw-mx-auto tw-px-5">
            <div className="tw-flex tw-flex-col tw-gap-6">
              <div className="tw-header-dashboard">
              <span className="tw-text-2xl tw-font-bold">
                Bienvenue {user["ufirstname"]} ! 👋
              </span>
              </div>
              <div className="tw-flex tw-w-full">
                <div className="tw-p-3 tw-bg-white tw-rounded-xl tw-border tw-border-light-grey tw-flex tw-flex-col tw-gap-2 tw-w-full tw-justify-between">
                  <div className="tw-flex tw-flex-row tw-justify-between tw-gap-5">
                  <span className="tw-text-2xl tw-font-semibold">
                    Informations
                  </span>
                    <Building className="tw-text-blue-500" />
                  </div>

                  <div className="tw-flex tw-flex-col">
                    <div className="tw-flex tw-gap-2 tw-justify-between tw-flex-wrap tw-px-1">
                      <div className="">
                        <p className="tw-text-md tw-font-semibold">Entreprise : {user["ucompany"]}</p>
                        <p className="tw-text-md tw-font-semibold">Titre : {user["utitle"]}</p>
                        <p className="tw-text-md tw-font-semibold">Nom : {user["ulastname"]}</p>
                      </div>
                    </div>

                  </div>

                  {transactions.length < 10 && (
                      <div className="row">

                        {surveys.length > 10 && (
                            <>
                              <div className="section-title text-left mb-35">
                                <h2></h2>
                              </div>
                              <div className="row align-items-left">
                              {surveys.map((row, index) => {
                                  return (
                                      <div
                                          className="tw-px-2 tw-py-1 tw-text-white tw-rounded-lg tw-cursor-pointer"
                                          key={"key" + index}
                                          style={{ margin: "10px" }}
                                      >
                                        <UncompleteBadge
                                            name={surveys[index]["surveyname"]}
                                            link={"/survey/" + surveys[index]["surveyid"]}
                                            key={"badge_" + index}
                                            step={"0" + index.toString()}
                                        />
                                      </div>
                                  );
                                })}
                              </div>
                            </>
                        )}
                      </div>
                  )}
                </div>
              </div>
              {auditDone && (
                  <div className="tw-header-dashboard">
                    <span className="tw-text-2xl tw-font-bold">
                      Audits disponibles
                    </span>
                  </div>
          )}

              <div className="tw-flex tw-gap-4 tw-w-full">
                {(transactions.length < 10 || user['uid'] === 80 || user['uid'] === 81 ) && (
                    <UncompleteBadge
                        name={"Audit"}
                        link={"/survey/" + user["uid"]}
                        key={"badge_1"}
                        step={"01"}
                    />
                )}

                {auditDone && (
                    <button
                        onClick={handlePdfDownload}
                        className="theme-btn"
                        style={{
                          display: 'inline-block',
                          width: '160px',
                          height: '60px',
                          padding: '20px',
                          margin: '20px',
                          textAlign: 'center',
                          lineHeight: '20px',
                          boxSizing: 'border-box'
                        }}
                    >
                      Réponses PDF
                    </button>
                )}
                {auditISO && auditDone && (
                    <div
                        className={`tw-px-4 tw-py-2 tw-text-white tw-rounded-lg tw-cursor-pointer ${
                            activeAudit === "ISO"
                                ? "tw-bg-main-blue"
                                : "tw-bg-gray-300 " +
                                (auditISO ? "" : "tw-cursor-not-allowed")
                        }`}
                        onClick={() => handleToggle("ISO")}
                    >
                      AUDIT ISO 27001
                    </div>

                )}
              </div>
              {activeAudit === "NIS" && auditNIS && auditDone && (
                  <div className="audinis tw-flex tw-flex-col tw-gap-6">
                    <div className="tw-flex tw-gap-6 tw-flex-1 tw-flex-col lg:tw-flex-row tw-flex-wrap">
                      <DonutGraphic
                          sybescore={sybeScore(auditNIS.score)}
                          score={auditNIS.score}
                          resultats={getResultTextConformite(auditNIS.score)}
                          title={"Niveau de conformité globale"}
                          tooltip={
                            "Le niveau de conformité globale est calculé en fonction de la conformité de vos audits."
                          }
                      />
                      <DonutGraphic
                          sybescore={sybeScore(100-auditNIS.score)}
                          score={100-auditNIS.score}
                          resultats={getResultTextRisque(100-auditNIS.score)}
                          title={"Niveau de risque global"}
                          tooltip={
                            "Le niveau de risque global est calculé en fonction de la conformité de vos audits."
                          }
                      />
                      <BarGraphicsCategories
                          scores={{
                            cat1: auditNIS.cat1_score,
                            cat2: auditNIS.cat2_score,
                            cat3: auditNIS.cat3_score,
                            cat4: auditNIS.cat4_score,
                          }}
                          title={"Conformité par categories"}
                          tooltip={"Le score de conformité par catégorie."}
                      />
                    </div>
                    <div className="tw-wrapper-content tw-flex tw-flex-col tw-gap-4">
                      <section className="left tw-flex tw-flex-col tw-gap-4">
                        <div className="tw-tabs">
                          <div className="tw-items-tabs tw-flex tw-bg-white tw-flex-col md:tw-flex-row">
                            <div
                                className="tw-item-tab tw-bg-light-blue tw-p-4 hover:tw-cursor-pointer tw-shadow-inner hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab0"
                                onClick={() => handleTabClick("tab0")}
                            >
                              <span>Matériel et logiciel</span>
                            </div>
                            <div
                                className="tw-item-tab tw-p-4 hover:tw-cursor-pointer hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab1"
                                onClick={() => handleTabClick("tab1")}
                            >
                              <span>Sauvegarde système</span>
                            </div>
                            <div
                                className="tw-item-tab tw-p-4 hover:tw-cursor-pointer hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab2"
                                onClick={() => handleTabClick("tab2")}
                            >
                              <span>Sécurité et authentification</span>
                            </div>
                            <div
                                className="tw-item-tab tw-p-4 hover:tw-cursor-pointer hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab3"
                                onClick={() => handleTabClick("tab3")}
                            >
                              <span>Protection</span>
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab0"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab0"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditNIS?.cat1_score}
                                  resultText={getResultTextConformite(auditNIS?.cat1_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat1 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat1}
                                  resultText={getResultTextRisque(riskConformityNIS.cat1)}
                                  iconClass={`${
                                      riskConformityNIS.cat1 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab1"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab1"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditNIS?.cat2_score}
                                  resultText={getResultTextConformite(auditNIS?.cat2_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat2 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat2}
                                  resultText={getResultTextRisque(riskConformityNIS.cat2)}
                                  iconClass={`${
                                      riskConformityNIS.cat2 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab2"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab2"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditNIS?.cat3_score}
                                  resultText={getResultTextConformite(auditNIS?.cat3_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat3 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat3}
                                  resultText={getResultTextRisque(riskConformityNIS.cat3)}
                                  iconClass={`${
                                      riskConformityNIS.cat3 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab3"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab3"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditNIS?.cat4_score}
                                  resultText={getResultTextConformite(auditNIS?.cat4_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat4 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat4}
                                  resultText={getResultTextRisque(riskConformityNIS.cat4)}
                                  iconClass={`${
                                      riskConformityNIS.cat4 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
              )}
              {activeAudit === "ISO" && auditISO && (
                  <div className="auditiso tw-flex tw-flex-col tw-gap-6">
                    <div className="tw-flex tw-gap-6 tw-flex-1 tw-flex-col lg:tw-flex-row tw-flex-wrap">
                      <DonutGraphic
                          sybescore={sybeScore(auditISO.score)}
                          score={auditISO.score}
                          resultats={getResultTextConformite(auditISO.score)}
                          title={"Niveau de conformité globale"}
                          tooltip={
                            "Le niveau de conformité globale est calculé en fonction de la conformité de vos audits."
                          }
                      />
                      <DonutGraphic
                          sybescore={sybeScore(auditISO.score)}
                          score={riskConformityNIS.total}
                          resultats={getResultTextRisque(auditISO.score)}
                          title={"Niveau de risque global"}
                          tooltip={
                            "Le niveau de risque global est calculé en fonction de la conformité de vos audits."
                          }
                      />
                      <BarGraphicsCategories
                          scores={{
                            cat1: auditISO.cat1_score,
                            cat2: auditISO.cat2_score,
                            cat3: auditISO.cat3_score,
                            cat4: auditISO.cat4_score,
                          }}
                          title={"Conformité par categories"}
                          tooltip={"Le score de conformité par catégorie."}
                      />
                    </div>
                    <div className="tw-wrapper-content tw-flex tw-flex-col tw-gap-4">
                      <section className="left tw-flex tw-flex-col tw-gap-4">
                        <div className="tw-tabs">
                          <div className="tw-items-tabs tw-flex tw-bg-white tw-flex-col md:tw-flex-row">
                            <div
                                className="tw-item-tab tw-bg-light-blue tw-p-4 hover:tw-cursor-pointer tw-shadow-inner hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab0"
                                onClick={() => handleTabClick("tab0")}
                            >
                              <span>Matériel et logiciel</span>
                            </div>
                            <div
                                className="tw-item-tab tw-p-4 hover:tw-cursor-pointer hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab1"
                                onClick={() => handleTabClick("tab1")}
                            >
                              <span>Sauvegarde système</span>
                            </div>
                            <div
                                className="tw-item-tab tw-p-4 hover:tw-cursor-pointer hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab2"
                                onClick={() => handleTabClick("tab2")}
                            >
                              <span>Sécurité et authentification</span>
                            </div>
                            <div
                                className="tw-item-tab tw-p-4 hover:tw-cursor-pointer hover:tw-bg-light-blue hover:tw-shadow-inner"
                                data-tab="tab3"
                                onClick={() => handleTabClick("tab3")}
                            >
                              <span>Protection</span>
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab0"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab0"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditISO?.cat1_score}
                                  resultText={getResultTextConformite(auditISO?.cat1_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat1 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat1}
                                  resultText={getResultTextRisque(riskConformityNIS.cat1)}
                                  iconClass={`${
                                      riskConformityNIS.cat1 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab1"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab1"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditISO?.cat2_score}
                                  resultText={getResultTextConformite(auditISO?.cat2_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat2 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat2}
                                  resultText={getResultTextRisque(riskConformityNIS.cat2)}
                                  iconClass={`${
                                      riskConformityNIS.cat2 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}


                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab2"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab2"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditISO?.cat3_score}
                                  resultText={getResultTextConformite(auditISO?.cat3_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat3 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat3}
                                  resultText={getResultTextRisque(riskConformityNIS.cat3)}
                                  iconClass={`${
                                      riskConformityNIS.cat3 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                          <div
                              className={
                                activeTab === "tab3"
                                    ? "tw-tab tw-py-4 tw-active tw-transition-opacity tw-duration-500 tw-ease-in "
                                    : "tw-tab tw-hidden tw-transition-opacity tw-duration-500 tw-ease-out tw-opacity-0"
                              }
                              id="tab3"
                          >
                            <div className="tw-grid tw-gap-4 tw-justify-between tw-flex-wrap sm:tw-grid-cols-1 md:tw-grid-cols-2 tw-grid-cols-1 ">
                              <ConformitySection
                                  score={auditISO?.cat4_score}
                                  resultText={getResultTextConformite(auditISO?.cat4_score)}
                                  iconClass={`${
                                      riskConformityNIS.cat4 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <RiskSection
                                  score={riskConformityNIS.cat4}
                                  resultText={getResultTextRisque(riskConformityNIS.cat4)}
                                  iconClass={`${
                                      riskConformityNIS.cat4 < 50
                                          ? "tw-text-blue-500"
                                          : "tw-text-red-500"
                                  }`}
                              />
                              <TimeSection hours={8} />
                              <CallToActionSection
                                  title={"Réserver un appel pour échanger sur vos résultats"}
                              />
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
              )}
              {transactions.length < 10 && auditDone && (
                  <section className="right tw-flex tw-flex-col tw-gap-4 tw-mb-10">
                    <div className="tw-flex tw-flex-row tw-gap-4 tw-justify-between tw-flex-wrap">
                      <div className="tw-p-3 tw-bg-white tw-rounded-xl tw-border tw-flex-auto tw-flex tw-flex-col tw-gap-6">
                        <div className="tw-flex tw-justify-between tw-border-b tw-pb-3">
                          <div className="tw-flex tw-flex-col">
                        <span className="tw-font-semibold">
                          Historique des audits
                        </span>
                          </div>
                          <History className="tw-text-blue-500" />
                        </div>
                        <div className="tw-flex tw-flex-col tw-gap-4">
                          {surveys.map((row, index) => (
                              <div
                                  key={"key" + index}
                                  className={`tw-flex tw-flex-row tw-gap-4 tw-justify-between tw-items-center ${
                                      index !== surveys.length - 1
                                          ? "tw-border-b tw-pb-2"
                                          : ""
                                  }`}
                              >
                          <span className="tw-text-sm tw-font-semibold tw-shrink-0">
                            {row["surveyname"]}
                          </span>
                                <span className="tw-text-sm tw-text-gray-400">25%</span>
                                <span className="tw-text-sm tw-p-2 tw-bg-green-300 tw-font-semibold tw-rounded-sm">
                            Terminé
                          </span>
                                <div className="tw-flex tw-gap-2">
                            <span className="tw-text-sm tw-text-gray-400">
                              {row["surveydescription"]}
                            </span>
                                </div>
                              </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </section>
              )}
            </div>
          </div>
        </section>
        <section
            key="section2"
            className="featured-section bgs-cover rpt-150 pb-120 rpb-100 py-120"
        >
          {transactions.length > 5000 && (
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-8">
                    {products.map((row, index) => {
                      return (
                          <div
                              className="section-title text-left mb-15"
                              key={"k_" + index}
                          >
                            <h4>{row["name"]}</h4>
                            {row["ingress"]}
                            <br />
                            {row["description"]}
                            <br />
                            EUR {row["price"]}
                            <br />
                            <br />
                            <StripeCheckoutButton
                                price={row["price"]}
                                action={onToken}
                                productid={row["id"]}
                                productname={row["name"]}
                                productdesc={row["description"]}
                            />
                          </div>
                      );
                    })}
                  </div>
                  {paymentFeedback !== "" && (
                      <div className="col-lg-6 col-md-8">
                        <h4>Payment </h4>
                        {paymentFeedback}
                      </div>
                  )}
                </div>
              </div>
          )}
        </section>
      </>
  );
};

export default Summary;
