import React, { useState, useEffect } from "react";
import FooterV1 from "../library/FooterV1";
import { Link, useParams } from "react-router-dom";
import GalleryV2Data from "../library/GalleryV2Data.json";
import { MoveUpRight } from "lucide-react";

const Portfoliodetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const selectedProduct = GalleryV2Data.find(
        (product) => product.id === parseInt(id)
    );
    if (selectedProduct) {
      setProduct(selectedProduct);
    }
  }, [id]);

  const allServices = GalleryV2Data;
  return (
      <>
        {product && (
            <section className="portfolio-details pt-120 rpt-100 pb-90 rpb-100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="portfolio-details-content rmb-55">
                      <h2>{product.title}</h2>
                      <div className="image mb-35">
                        <img
                            className="tw-rounded-xl"
                            src={`/assets/images/services/${product.thumb}`}
                            alt="Portfolio Details"
                        />
                      </div>

                      <h5>{product.subtitle}</h5>
                      <p>{product.subdescription}</p>

                      {product.levels && (
                          <ul className="tw-mb-8">
                            {product.levels.map((level, index) => (
                                <li key={index}>
                                  <span className="tw-font-bold">{level.level} :</span> {level.range}
                                </li>
                            ))}
                          </ul>
                      )}

                      <p>{product.conclusion}</p>
                      <h5>Description</h5>
                      <p>{product.description}</p>
                      <div className="row mt-45">
                        <div className="col-sm-6 tw-flex tw-flex-col tw-gap-6 tw-justify-between tw-items-center tw-mb-6"
                        >
                          <div className="image">
                            <img
                                className="tw-w-20"
                                src={`/assets/images/services/${product.botimg1}`}
                                alt="Portfolio"
                            />
                          </div>
                          <span className="tw-font-bold">{product.botDesc1}</span>
                        </div>
                        <div className="col-sm-6 tw-flex tw-flex-col tw-gap-6 tw-justify-between tw-items-center tw-mb-6"
                        >
                          <div className="image">
                            <img
                                className="tw-w-20"
                                src={`/assets/images/services/${product.botimg2}`}
                                alt="Portfolio"
                            />
                          </div>
                          <span className="tw-font-bold">{product.botDesc2}</span>
                        </div>
                        <section className="tw-p-20 tw-w-full tw-mx-3 tw-bg-light-blue tw-flex tw-flex-col tw-justify-center tw-items-center tw-rounded-xl">
                          <div className="tw-container tw-flex tw-flex-col tw-justify-center tw-gap-4 tw-items-center tw-w-full">
                            <h3 className="tw-text-2xl">
                              Des questions concernant nos solutions ?
                            </h3>
                            <div className="tw-flex tw-flex-row tw-gap-10">
                              <div className="hero-btn fade-in-up">
                                <Link to="/contact" className="theme-btn">
                                  Nous contacter
                                </Link>
                              </div>
                            </div>
                          </div>
                        </section>

                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="portfolio-sidebar">
                        <div className="widget widget-portfolio-info bg-lighter p-45 wow fadeInUp delay-0-3s">
                            <h3 className="widget-title">Service</h3>
                            <ul>
                                <li>
                                    <h6>{product.title}</h6>
                                    <p>{product.price}</p>
                                </li>
                            </ul>
                            <p></p>
                            <h3 className="widget-title">Description</h3>
                            <ul>
                                <li>
                                    <p>{product.description}</p>
                                </li>
                            </ul>
                            <p></p>
                            <h3 className="widget-title">Accesibilité</h3>
                            <ul>
                                <li>
                                    <p>{product.access}</p>
                                </li>
                            </ul>
                            {product.link !== "" && (
                                <>
                                    <h3 className="widget-title">Lien</h3>
                                    <ul>
                                        <li>
                                            <a href={product.link} target="_blank" rel="noopener noreferrer">
                                                {product.link}
                                            </a>
                                        </li>
                                    </ul>
                                </>
                            )}

                        </div>
                        {product.title === "Audit NIS" && (
                            <div className="hero-btn mt-35 tw-mb-8">
                                <Link
                                    to="/inscribe"
                                    className="theme-btn"
                                style={{ display: "grid", placeItems: "center" }}
                            >
                              Commencer
                            </Link>
                          </div>
                      )}
                      <div className="tw-flex tw-flex-col tw-gap-4">
                        {allServices.map((service) => (

                            <div
                                key={service.id}
                                className="tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full tw-bg-light-blue tw-p-3 tw-rounded tw-mb-3"
                            >
                              <Link
                                  to={`/portfoliodetails/${service.id}`}
                                  className="tw-w-full tw-flex tw-justify-between"
                              >
                                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                          <span className="tw-text-xl tw-font-bold">
                            {service.title}
                          </span>
                                </div>
                                <MoveUpRight />
                              </Link>
                            </div>

                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        )}
        ;
        <FooterV1 />
      </>
  );
};

export default Portfoliodetails;
