import React from 'react';
import StripeCheckout from 'react-stripe-checkout';

const StripeCheckoutButton = ({ price, action, productid, productname, productdesc }) => {

    const apiKey = process.env.STRIPEAPI;
    const handleToken = async (token) => {
        let modifiedToken = token;
        modifiedToken.productid = productid;
        modifiedToken.productname = productname;
        modifiedToken.productdesc = productdesc;
        modifiedToken.productprice = price * 100;
        await action(modifiedToken);
    };

    return (
        <StripeCheckout
            label="Accéder au paiement"
            name="Sybe Solutions"
            billingAddress
            shippingAddress
            image="https://sybe-solutions.com/assets/images/logos/stripe_logo.png"
            description={`Total EUR{price}`}
            amount={price * 100}
            productid={productid}
            panelLabel="Payer"
            token={handleToken}
            currency="EUR"
            stripeKey={"pk_live_51Ok8m2CSU8Oyc3e105OQONskX2squNmvJPDr7I4l8m4GwEGR0ftzr9NM68rukq1EsmsjY8YbUcyFHbt8hENxm5EG00KaNcm0dj"}
        />
    );
};

export default StripeCheckoutButton;
