import React from "react";
import { Info, BadgeCheck } from "lucide-react";
import Doughnut from "../../library/donut";
import { Tooltip } from "react-tooltip";

export default function DonutGraphic({ score, resultats, title, tooltip, sybescore }) {


  return (
      title === "Niveau de risque global" ? (
          <div className="tw-p-3 tw-bg-white tw-rounded-xl tw-border tw-flex tw-flex-1 tw-flex-col tw-gap-8">
            <div className="tw-flex tw-flex-col tw-gap-4 tw-h-full tw-justify-between tw-relative">

              <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
                {sybescore && (
                    <div className="tw-rounded-lg tw-p-2 tw-border-solid tw-border-2px tw-border-light-blue tw-flex tw-justify-center tw-items-center tw-gap-2">

                    </div>
                )}
              </div>

              <div className="tw-wrapper-donut tw-p-6 tw-relative tw-flex tw-justify-center tw-mx-auto tw-w-full tw-max-w-96">
                <Doughnut score={score} />
                <div className="tw-flex tw-justify-center tw-absolute tw-bottom-20 tw-left-0 tw-right-0">
                  <span className="tw-text-xl tw-font-bold tw-uppercase">{resultats}</span>
                </div>
              </div>

              <div className="tw-flex tw-justify-center tw-gap-5">
            <span className="tw-text-md tw-font-semibold">
              {title}
            </span>
                <Tooltip id="my-tooltip" />
                <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={tooltip}
                    data-tooltip-place="top-start"
                >
                  <Info />
                </a>
              </div>
            </div>
          </div>
      ) : (
          <div className="tw-p-3 tw-bg-white tw-rounded-xl tw-border tw-flex tw-flex-1 tw-flex-col tw-gap-8">
            <div className="tw-flex tw-flex-col tw-gap-4 tw-h-full tw-justify-between tw-relative">

              <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
                <Tooltip id="tooltip-sybescore"/>
                {sybescore && (
                    <div
                        className="tw-rounded-lg tw-p-2 tw-border-solid tw-border-2px tw-border-light-blue tw-flex tw-justify-center tw-items-center tw-gap-2 tw-w-full">
                <span className="tw-text-sm tw-font-semibold tw-text-main-blue">
                  <BadgeCheck/>
                </span>
                      <span className="tw-text-sm"><b>Sybe score : </b>{sybescore}</span>
                      <a
                          data-tooltip-id="my-tooltip"
                          data-tooltip-content="Niveau de conformité Sybe"
                          data-tooltip-place="top-start"
                      >
                        <Info className="tw-h-4"/>
                      </a>
                    </div>
                )}
              </div>

              <div
                  className="tw-wrapper-donut tw-p-6 tw-relative tw-flex tw-justify-center tw-mx-auto tw-w-full tw-max-w-96">
                <Doughnut score={score}/>
                <div className="tw-flex tw-justify-center tw-absolute tw-bottom-20 tw-left-0 tw-right-0">
                  <span className="tw-text-xl tw-font-bold tw-uppercase">{resultats}</span>
                </div>
              </div>

              <div className="tw-flex tw-justify-center tw-gap-5">
            <span className="tw-text-md tw-font-semibold">
              {title}
            </span>
                <Tooltip id="my-tooltip"/>
                <a
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content={tooltip}
                    data-tooltip-place="top-start"
                >
                  <Info/>
                </a>
              </div>
            </div>
          </div>
      )
  );
}
